import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { removeCoUsers } from 'entities/CoUser/api';
import { CoUser } from 'entities/CoUser/types';
import { format } from 'date-fns';
import { enqueueSnackbar } from 'notistack';
import { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { FeaturesEnum } from 'entities/Company/types';
import { ColorPalette } from 'shared/consts/colorPalette';
import { EmptyTable } from 'shared/ui/EmptyTable';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { getFilteredArray } from 'shared/helpers/getFilteredArray';
import { getSortedArray } from 'shared/helpers/getSortedArray';
import { useDebounce } from 'shared/helpers/useDebounce';
import { useSearchParam } from 'shared/helpers/useSearchParam';
import { OptionsDropdown } from 'shared/ui/OptionsDropdown';
import { Option } from 'shared/ui/OptionsDropdown/types';
import { SearchInput } from 'shared/ui/SearchInput';
import { TableWithHeader } from 'shared/ui/tables/TableWithHeader';
import { useCoUserDetailsInfoStore } from 'widgets/CoUsers/ui/CoUserDetailsInfo/model/state';
import { CoUsersDashboardColumns } from 'widgets/CoUsers/ui/CoUsersDashboard/consts/DashboardColumns';
import { SortOptionsList } from 'widgets/CoUsers/ui/CoUsersDashboard/consts/SortOptionsList';
import { RemoveCoUserModal } from 'widgets/CoUsers/ui/RemoveCoUserModal';
import { useSubscriptionStoreStore } from 'widgets/Subscriptions/model/state';
import { useAuthStore } from 'processes/auth/model/state';
import { getMaxValue } from 'shared/helpers/getMaxValue';
import { useFeature } from 'shared/hooks/useFeatures';
import CoUserFilters from '../CoUserFilters';
import Chip from 'shared/ui/Chip';
import { useCoUsersStore } from 'store/co-users';

interface Props {
	coUsers?: Array<CoUser>;
}

const defaultSelectedOption = SortOptionsList[0];
const defaultSearch = '';

export const CoUsersDashboard: FunctionComponent<Props> = ({ coUsers }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useAuthStore();
	const { coUsersFilters, setCoUsersFilters } = useCoUsersStore();
	const { getFeature } = useFeature();
	const { setSelectedCoUser } = useCoUserDetailsInfoStore();
	const { currentSubscription } = useSubscriptionStoreStore();

	// Sort and search params from URL
	const [sortParam, setSortParam] = useSearchParam('sort', defaultSelectedOption.value);
	const [searchParam, setSearchParam] = useSearchParam('search', defaultSearch);

	const initialSelectedOption = useMemo(() => {
		const initialSelectedOption = SortOptionsList.find((option) => option.value === sortParam);
		return initialSelectedOption ? initialSelectedOption : defaultSelectedOption;
	}, [sortParam]);
	const [selectedOption, setSelectedOption] = useState<Option>(initialSelectedOption);

	const initialSearchParam = useMemo(() => searchParam ?? defaultSearch, [searchParam]);
	const [searchInputValue, setSearchInputValue] = useState<string>(initialSearchParam);
	const debouncedSearchInputValue = useDebounce<string>(searchInputValue, 500);

	const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchInputValue(event.target.value);
	};

	const selectedTableFilters = useMemo(() => {
		if (coUsersFilters) {
			const { from, to, ...rest } = coUsersFilters;
			return ({
				date: from && to ? `${format(new Date(from), 'dd/MM/yyy')} - ${format(new Date(to), 'dd/MM/yyy')}` : undefined,
				...rest
			});
		}
		return [];
	}, [coUsersFilters]);

	useEffect(() => {
		setSearchParam(debouncedSearchInputValue);
	}, [debouncedSearchInputValue]);

	// CoUser removing flow
	const [coUserForRemove, setCoUserForRemove] = useState<CoUser | undefined>(undefined);
	const handleOpenModal = (coUser: CoUser) => setCoUserForRemove(coUser);
	const handleCloseModal = () => setCoUserForRemove(undefined);

	const queryClient = useQueryClient();
	const { mutate } = useMutation({
		mutationKey: ['co-users', coUserForRemove?._id],
		mutationFn: removeCoUsers,
	});

	const handleRemoveCoUser = useCallback(() => {
		mutate(coUserForRemove?._id, {
			onSuccess: async () => {
				await queryClient.invalidateQueries({ queryKey: ['co-users'] });
				enqueueSnackbar({
					message: t('co_users.removed_message', {
						value: coUserForRemove?.userId?.email ?? coUserForRemove?.email,
					}),
					variant: 'success',
				});
				handleCloseModal();
			},
			onError: (err) => {
				const defaultErrorMessage = t('co_users.error_while_removing', {
					value: coUserForRemove?.userId?.email ?? coUserForRemove?.email,
				});
				const errorMessage = getErrorMessage(err, defaultErrorMessage);
				enqueueSnackbar({
					message: errorMessage,
					variant: 'error',
				});
			},
		});
	}, [coUserForRemove, queryClient]);

	// Prepare CoUsers data for dashboard
	const preparedCoUsers = useMemo(() => {
		if (!coUsers) return [];
		return coUsers.map((coUser) => ({
			...coUser,
			openModal: (coUser: CoUser) => handleOpenModal(coUser),
			navigateToDetails: (coUser: CoUser) => {
				setSelectedCoUser(coUser);
				navigate('/co-users/details');
			},
		}));
	}, [coUsers]);

	const sortedCoUsers = useMemo(() => {
		if (!preparedCoUsers) return [];
		if (!sortParam) return preparedCoUsers;

		return getSortedArray(
			preparedCoUsers,
			{
				key: sortParam?.split(':')[0] as keyof CoUser,
				rule: sortParam?.split(':')[1] as 'asc' | 'desc',
			},
			{
				createdAt: (value) => new Date(value).getTime(),
				joinedAt: (value) => new Date(value).getTime(),
			},
		);
	}, [preparedCoUsers, sortParam]);

	const filteredCoUsers = useMemo(() => {
		if (!coUsers) return [];
		if (!searchParam) return sortedCoUsers;

		return getFilteredArray(
			sortedCoUsers,
			['userId?.email', 'email', 'userId.firstName', 'userId.lastName'],
			searchParam,
		);
	}, [sortedCoUsers, searchParam]);

	const rowsData = useMemo(() => {
		return filteredCoUsers.map((coUser) => ({
			...coUser,
			id: coUser._id,
			authUserId: user?._id,
			isDealer: getFeature(FeaturesEnum.RESELLER)?.isEnabled,
		}));
	}, [filteredCoUsers]);

	return (
		<>
			<RemoveCoUserModal
				coUser={coUserForRemove}
				open={!!coUserForRemove}
				handleClose={handleCloseModal}
				handleRemoveCoUser={handleRemoveCoUser}
			/>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					marginBottom: '34px',
				}}
			>
				<Typography
					variant={'h5'}
					color={ColorPalette.astronaut}
					sx={{
						marginRight: 'auto',
					}}
				>
					{t('co_users.your_co_users')} ({filteredCoUsers?.length}/
					{getMaxValue(
						currentSubscription?.maxCousers || 0,
						currentSubscription?.maxFreeCousers || 0,
					)}
					)
				</Typography>
				<Box
					sx={{
						'display': 'flex',
						'flexDirection': 'row',
						'alignItems': 'center',
						'gap': 2
					}}
				>
					<SearchInput value={searchInputValue} onChange={handleSearchInputChange} disabled={!!coUsers?.length} />
					{coUsers?.length ? (
						<OptionsDropdown
							iconId={'sort_arrow'}
							optionList={SortOptionsList}
							selectedOption={selectedOption}
							onOptionSelect={(option) => {
								setSelectedOption(option);
								setSortParam(option.value);
							}}
						/>
					) : null}
					<CoUserFilters />
				</Box>
			</Box>
			<Box sx={{ display: 'flex', gap: 1, marginBottom: 1 }}>
				{Object.entries(selectedTableFilters).map(([key, value]) => value && (
					<Chip
						key={key}
						label={value as string}
						onDelete={() => {
							if (key === 'date') {
								setCoUsersFilters({ ...coUsersFilters, from: undefined, to: undefined })
							} else {
								setCoUsersFilters({ ...coUsersFilters, [key]: undefined })
							}
						}}
						color="secondary"
						size="small"
					/>
				))}
			</Box>
			{coUsers?.length ? (
				<TableWithHeader columns={CoUsersDashboardColumns} rows={rowsData} />
			) : (
				<EmptyTable text={t('co_users.didnt_invite_yet')} />
			)}
		</>
	);
};
