import { Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getCoUsers, getCoUserDealers } from 'entities/CoUser/api';
import { FunctionComponent, useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Button } from 'shared/ui/Button';
import { CoUsersDashboard } from 'widgets/CoUsers/ui/CoUsersDashboard';
import { InviteCoUserModal } from 'widgets/CoUsers/ui/InviteCoUserModal';
import { useSubscriptionStoreStore } from 'widgets/Subscriptions/model/state';
import { getMaxValue } from 'shared/helpers/getMaxValue';
import { STypesEnum } from 'entities/Subscriptions/enums';
import { useCoUsersStore } from 'store/co-users';

export const CoUsersWidget: FunctionComponent = () => {
	const { t } = useTranslation();
	const { setDealersList, coUsersFilters } = useCoUsersStore();

	const { data: coUsersData, isLoading } = useQuery({
		queryKey: ['co-users', coUsersFilters],
		queryFn: () => getCoUsers(coUsersFilters),
	});

	useQuery({
		queryKey: ['co-users/dealers'],
		queryFn: () => getCoUserDealers(),
		onSuccess: (response) => {
			setDealersList(response?.data);
		},
	});

	const { currentSubscription } = useSubscriptionStoreStore();

	const [openModal, setOpenModal] = useState(false);
	const handleOpen = () => setOpenModal(true);
	const handleClose = () => setOpenModal(false);

	const isDisable = useMemo(() => {
		if (
			isLoading ||
			(coUsersData?.data.length ===
				getMaxValue(currentSubscription?.maxCousers || 0, currentSubscription?.maxFreeCousers || 0) &&
				currentSubscription?.subscriptionType !== STypesEnum.PAY_AS_YOU_GO &&
				currentSubscription?.subscriptionType !== STypesEnum.UNLIMITED)
		)
			return true;
		return false;
	}, [coUsersData?.data, currentSubscription, isLoading]);

	return (
		<>
			<InviteCoUserModal open={openModal} handleClose={handleClose} />
			<Typography variant={'h4'} color={ColorPalette.astronaut}>
				{t('co_users.title')}
			</Typography>
			<Typography variant={'subtitle1'} color={ColorPalette.baliHai} sx={{ marginTop: '24px' }}>
				{t('co_users.description')}
			</Typography>

			<Button
				sx={{ position: 'absolute', top: '30px', right: '40px' }}
				onClick={handleOpen}
				disabled={isDisable}
			>
				{t('co_users.invite_co_user')}
			</Button>

			<Divider color={ColorPalette.periwinkle} sx={{ margin: '42px 0 44px 0' }} />

			<CoUsersDashboard coUsers={coUsersData?.data ?? []} />
		</>
	);
};
