import { CoUser } from 'entities/CoUser/types';
import { IconButton } from 'shared/ui/IconButton';
import { Column } from 'shared/ui/tables/_common/types';
import { TableTranslationLabel } from 'shared/ui/tables/_common/ui/TableTranslationLabel';
import { ColorPalette } from 'shared/consts/colorPalette';

type CoUsersTableColumnsType = CoUser & {
	openModal: (coUser: CoUser) => void;
	importedCounter: number;
	totalPagesCounter: number;
}

export const CoUsersTableColumns: Array<Column<CoUsersTableColumnsType>> = [
	{
		field: 'email',
		commonCellSettings: {
			style: {
				minWidth: '250px',
				maxWidth: '250px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="email" />,
		},
		bodyCellSettings: {
			prepareField: (user) => user?.userId?.email ?? '-',
		},
	},
	{
		field: 'firstName',
		commonCellSettings: {
			style: {
				minWidth: '200px',
				maxWidth: '200px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="first_name" />,
		},
		bodyCellSettings: {
			prepareField: (user) => user?.userId?.firstName.trim() || '-',
		},
	},
	{
		field: 'lastName',
		commonCellSettings: {
			style: {
				minWidth: '200px',
				maxWidth: '200px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="last_name" />,
		},
		bodyCellSettings: {
			prepareField: (user) => user?.userId?.lastName.trim() || '-',
		},
	},
	{
		field: 'dealerName',
		commonCellSettings: {
			style: {
				minWidth: '130px',
				maxWidth: '130px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="dealer_name" />,
		},
		bodyCellSettings: {
			renderCell: (coUser) => coUser?.dealerName || '-',
		},
	},
	{
		field: 'companyName',
		commonCellSettings: {
			style: {
				minWidth: '130px',
				maxWidth: '130px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="company_name" />,
		},
		bodyCellSettings: {
			renderCell: (coUser) => coUser?.companyName || '-',
		},
	},
	{
		field: 'importedFiles',
		commonCellSettings: {
			style: {
				minWidth: '130px',
				maxWidth: '130px',
			},
		},
		headerCellSettings: {
			renderCell: (coUser) => <><TableTranslationLabel label="imported_files" /> {`(${coUser?.importedCounter})`}</>,
		},
		bodyCellSettings: {
			prepareField: (coUser) => {
				return coUser?.filesCounter || '-';
			},
		},
	},
	{
		field: 'totalPages',
		commonCellSettings: {
			style: {
				minWidth: '130px',
				maxWidth: '130px',
			},
		},
		headerCellSettings: {
			renderCell: (coUser) => <><TableTranslationLabel label="total_pages" /> {`(${coUser?.totalPagesCounter})`}</>,
		},
		bodyCellSettings: {
			renderCell: (coUser) => coUser?.totalPages || '-',
		},
	},
	{
		field: 'options',
		commonCellSettings: {
			style: { width: '100px' },
			align: 'center',
		},
		bodyCellSettings: {
			renderCell: (coUser) => coUser?._id ? (
				<IconButton
					sxWrapper={{ backgroundColor: ColorPalette.violetRed }}
					sxIcon={{ color: ColorPalette.white }}
					onClick={() => coUser?.openModal(coUser)}
					iconId="trash"
				/>
			) : null,
		},
	},
];
