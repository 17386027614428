import { axiosInstance } from 'shared/config/axios';
import {
	GetUsersRequest,
	GetUserRequest,
	RemoveUserRequest,
	GetCoUsersOfUserRequest,
	RemoveCoUserOfUserRequest,
	PostUserFeaturesRequest,
	GetAdminInvoisesRequest,
	GetCoUsersDealersRequest,
} from './types';

export const getUsers: GetUsersRequest = (queries) => {
	return axiosInstance.get('admin/users', { params: { ...queries } });
};

export const getAdminUser: GetUserRequest = (userId) => {
	return axiosInstance.get(`/admin/users/${userId}`);
};

export const updateAdditionalFeatures: PostUserFeaturesRequest = ({ userId, data }) => {
	return axiosInstance.patch(`/admin/users/${userId}/features`, data);
};

export const removeUser: RemoveUserRequest = (userId) => {
	return axiosInstance.delete(`/admin/users/${userId}`);
};

export const getCoUsersOfUser: GetCoUsersOfUserRequest = ({ userId, ...queries }) => {
	return axiosInstance.get(`admin/users/${userId}/co-users`, { params: { ...queries } });
};

export const removeCoUserOfUser: RemoveCoUserOfUserRequest = (userId) => {
	return axiosInstance.delete(`/admin/co-users/${userId}`);
};

export const getUserInvoice: GetAdminInvoisesRequest = ({ userId, ...queries }) => {
	return axiosInstance.get(`admin/users/${userId}/invoices`, { params: { ...queries } });
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PatchUnlimitedSubscription: any = (userId: string) => {
	return axiosInstance.patch(`admin/users/${userId}/unlimited`);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getUserSubscriptions: any = (userId: string) => {
	return axiosInstance.get(`admin/users/${userId}/subscriptions`);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getResources: any = () => {
	return axiosInstance.get(`admin/resources`);
};

export const getAdminCoUserDealers: GetCoUsersDealersRequest = ({ userId }) => {
	return axiosInstance.get(`/admin/users/${userId}/co-users/dealers`);
};
