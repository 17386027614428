import {
	GetCoUsersRequest,
	InviteCoUserRequest,
	RemoveCoUserRequest,
	GetCoUsersDealersRequest,
} from 'entities/CoUser/api/types';
import { axiosInstance } from 'shared/config/axios';

export const getCoUsers: GetCoUsersRequest = (queries) => {
	return axiosInstance.get('/co-users', { params: { ...queries } });
};

export const inviteCoUser: InviteCoUserRequest = (data) => {
	return axiosInstance.post('/co-users/invite', data);
};

export const removeCoUsers: RemoveCoUserRequest = (userId) => {
	return axiosInstance.delete(`/co-users/delete/${userId}`);
};

export const getCoUserDealers: GetCoUsersDealersRequest = () => {
	return axiosInstance.get('/co-users/dealers');
};
