import { create } from 'zustand';
import { GetCoUsersReq } from 'entities/CoUser/api/types';

type CoUsersStore = {
	dealersList: string[] | null;
	coUsersFilters: GetCoUsersReq | undefined;
};

type CoUsersActions = {
	setDealersList: (list: string[]) => void;
	setCoUsersFilters: (filters: GetCoUsersReq) => void;
	reset: () => void;
};

const initialState: CoUsersStore = {
	dealersList: null,
	coUsersFilters: undefined,
};

const coUsersStore = create<CoUsersStore & CoUsersActions>()((set) => ({
	...initialState,
	setDealersList: (dealersList) => set(() => ({ dealersList })),
	setCoUsersFilters: (coUsersFilters) => set(() => ({ coUsersFilters })),
	reset: () => set(() => ({ ...initialState })),
}));

export const useCoUsersStore = () => coUsersStore((state) => state);
